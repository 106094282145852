import { Button, Grid, Menu, Layout, Paper, Table, Alert, ButtonGroup, Loader, Accordion, AccordionDetails, AccordionSummary, Typography } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import currency from 'currency.js'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAPI, useAxios } from '../../hooks'
import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth";
import { Chip, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, formatDateTime, formatMoney, getBidStatusColor, getBidStatusLabel, getOrderConfirmationColor, getOrderConfirmationLabel, getPaymentStatusColor, getPaymentStatusLabel } from "../../utils";
import {useSnackbar, enqueueSnackbar} from 'notistack'
import { AxiosError } from "axios";
import { useGridApiRef } from "@mui/x-data-grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {useState} from 'react'

export function Orders () {
    const {me} = useContext(AuthContext)
    const navigate = useNavigate()

    const [orders, refetchOrders] = useAxios(`/order`)
    const [programs, refetchPrograms] = useAxios(`/program`)
    const [api, callAPI] = useAxios({}, {manual: true})
    const [orderVerified, verifyOrder] = useAxios({}, {manual: true})

    const [verifyingOrders, setVerifyingOrders] = useState<Array<number>>([])
    
    return (
        <Layout>
            <Grid container justifyContent="center" alignItems="flex-start" padding={3} spacing={2}>
                {api.loading || orders.loading || programs.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                    <Grid item xs={12} sm={11}>
                        <Alert color="info" icon={false}>
                            Esta listagem inicialmente apenas mostra emissões com pagamento <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Aguardando Confirmação</span>.<br/>Para ver todos os registros, limpe os filtros da tabela.
                        </Alert>
                    </Grid>
                    {me?.role === 'SELLER' &&  (<Grid item xs={12} sm={11} paddingBottom={2}>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            >
                            <Typography fontWeight="bold">Tabela de Preços</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Table 
                                columns={[
                                    { field: 'id', headerName: 'Programa', valueGetter: ({row}) => COMPANY_OPTIONS.find(item => item.value === row.company)?.label, flex: 1 },
                                    { field: 'sell_price', headerName: 'Valor de Venda (R$)', flex: 1, valueFormatter: ({value}) => formatMoney(value) },
                                ]}
                                getRowId={({company}) => company}
                                hideFooterPagination
                                rows={programs.data || []}
                                hideFooter
                                slots={undefined}
                            />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>)}
                    <Grid item xs={12} sm={10} textAlign="right">
                        <Button href="/admin/emissoes/nova">
                            Adicionar
                        </Button>
                    </Grid>
                <Grid item xs={12} sm={11}>
                    <Paper elevation={1}>
                    <Table 
                        columnVisibilityModel={{
                            actions: me?.role === 'ADMIN',
                            supplier_name: me?.role !== 'AGENCY'
                        }}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [
                                        { 
                                            field: 'payment.status',
                                            operator: 'equals',
                                            value: 'Aguardando Confirmação'
                                        }
                                    ]
                                }
                            }
                        }}
                        columns={[
                            { field: 'verification', align: 'center', headerName: '', type:'boolean', valueGetter: ({ row }) => {
                                const verification = row.order_verifications[0]

                                return !verification || !verification.valid ? false : true
                            }, width: 20, renderCell: ({row}) => {
                                if (verifyingOrders.includes(row.id)) {
                                    return <Loader style={{
                                        height: 20,
                                        width: 20
                                    }} />
                                }

                                const verification = row.order_verifications[0]

                                if (!['Gol Transportes Aéreos', 'LATAM Airlines', 'LATAM Colombia', 'LATAM Peru', 'LATAM Brasil', 'LATAM Paraguay', 'LATAM Argentina', 'LATAM Ecuador', 'TAP Portugal', 'Azul'].includes(row.airline)) {
                                    return (
                                        <Tooltip
                                            title={`Ainda estamos trabalhando as verificações automáticas para esta companhia`}
                                        >
                                            <EngineeringIcon color="action" />
                                        </Tooltip>
                                    )
                                }

                                if (!row.pax_name || !row.origin_airport_code || !row.reservation_code) {
                                    return (
                                        <Tooltip
                                            title={`Esta reserva não possui informações necessárias para a verificação automática (nome do passageiro, aeroporto de origem e localizador)`}
                                        >
                                            <ReportProblemIcon color="warning" />
                                        </Tooltip>
                                    ) 
                                }

                                if (!verification) {
                                    return (
                                        <Tooltip
                                            title={`Esta reserva ainda não foi verificada automaticamente.`}
                                        >
                                            <HourglassTopIcon color="action" />
                                        </Tooltip>
                                    )
                                } else {
                                    return (
                                        <Tooltip
                                            title={`Última Verificação: ${formatDateTime(verification.created_at)}`}
                                        >
                                            {verification.valid ? <CheckIcon color="success"/> : <CloseIcon color="error"/>}
                                        </Tooltip>
                                    )
                                }
                            }},
                            { field: 'id', headerName: 'ID', width: 100 },
                            { field: 'created_at', headerName: 'Data', flex: 1,type: 'date', valueGetter: ({value}) => new Date(value), valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'flight_date', type: 'date', headerName: 'Data do Voo', valueGetter: ({row}) => new Date(row.flight_date), valueFormatter: ({value}) => formatDateTime(value), width: 120 },
                            { field: 'author_name', headerName: 'Emissor', flex: 1 },
                            { field: 'reservation_code', headerName: 'Localizador', width: 120 },
                            { field: 'supplier_name', headerName: 'Fornecedor', flex: 1 },
                            { field: 'airline', headerName: 'Companhia', flex: 1 },
                            { field: 'company', headerName: 'Programa', flex: 1, valueGetter: ({row}) => row.bid.company, valueFormatter: ({value}) => COMPANY_OPTIONS.find(item => item.value === value)?.label },
                            { field: 'miles', headerName: 'Qtd.', width: 80, valueFormatter: ({value }) => {
                                return Number(value).toLocaleString('pt-BR')
                            }},
                            { field: 'custom_payment', headerName: 'Pag. a Prazo', width: 100, type: 'boolean', valueGetter: ({row}) => row.bid.accepts_custom_payment },
                            {
                                field: 'payment.status', headerName: 'Pagamento', flex: 1, 
                                valueGetter: ({row}) => {
                                    return getPaymentStatusLabel(row.payment.status)
                                },
                                renderCell: ({ formattedValue, row }) => {
                                    return (
                                        <Chip color={getPaymentStatusColor(row.payment.status)} label={formattedValue} />
                                    )
                                }
                            },
                            {
                                field: 'confirmation', headerName: 'Status (48h)', flex: 1, 
                                valueGetter: ({value}) => {
                                    return getOrderConfirmationLabel(value)
                                },
                                renderCell: ({ formattedValue, row }) => {
                                    return (
                                        <Chip color={getOrderConfirmationColor(row)} label={formattedValue} />
                                    )
                                }
                            },
                            {
                                field: 'actions', headerName: '', sortable: false, filterable: false, width: 100, disableColumnMenu: true, renderCell: ({row}) => {
                                    const menuOptions: Array<{
                                        label: string,
                                        onClick: () => void,
                                        disabled?: boolean
                                    }> = [
                                    { 
                                        label: 'Editar', 
                                        onClick: async () => {
                                            navigate(`/admin/emissoes/${row.id}`)
                                        }
                                    },
                                    ]

                                    if ((row.confirmation === 'CONFIRMED' || row.confirmation === 'PENDING') && row.payment.status !== 'PAID') {
                                        menuOptions.push({ 
                                            label: 'Cancelar', 
                                            onClick: async () => {
                                                await callAPI({
                                                    url: `/order/${row.id}/cancel`,
                                                    method: 'POST'
                                                })
                                                refetchOrders()
                                            }
                                        })
                                    }

                                    if ((row.confirmation === 'CANCELLED' || row.confirmation === 'PENDING') && row.payment.status !== 'PAID') {
                                        menuOptions.push({ 
                                            label: 'Confirmar', 
                                            onClick: async () => {
                                                await callAPI({
                                                    url: `/order/${row.id}/confirm`,
                                                    method: 'POST'
                                                })
                                                refetchOrders()
                                            }
                                        })
                                    }

                                    if (row.bid.status !== 'DONE' && row.payment.status !== 'PAID') {
                                        menuOptions.push({ 
                                            label: 'Excluir', 
                                            onClick: async () => {
                                                await callAPI({
                                                    url: `/order/${row.id}`,
                                                    method: 'DELETE'
                                                })
                                                refetchOrders()
                                            }
                                        })
                                    }

                                    if (moment(row.flight_date).isAfter(new Date())) {
                                        if (row.pax_name && row.origin_airport_code && row.reservation_code) {
                                            if (['LATAM Airlines', 'Gol Transportes Aéreos', 'TAP Portugal', 'Azul'].includes(row.airline)) {
                                                menuOptions.push({ 
                                                    label: 'Verificar Bilhete', 
                                                    disabled: verifyingOrders.includes(row.id),
                                                    onClick: async () => {
                                                        setVerifyingOrders(state => {
                                                            return [
                                                                ...state,
                                                                row.id
                                                            ]
                                                        })

                                                        verifyOrder({
                                                            url: `/order/${row.id}/verify`,
                                                            method: 'POST'
                                                        }).finally(() => {
                                                            setVerifyingOrders(state => {
                                                                return state.filter(item => item !== row.id)
                                                            })

                                                            refetchOrders()
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    
                                    return (
                                        <ButtonGroup>
                                            <Menu
                                                options={menuOptions}
                                            >
                                                <MoreHorizIcon />
                                            </Menu>
                                        </ButtonGroup>
                                    )
                                }
                            }
                        ]}
                        rows={(orders?.data || []).map((item: { bid: { user: { name: string }}}) => ({
                            ...item,
                            supplier_name: me?.role !== 'AGENCY' ? item.bid.user.name : '',
                        }))}
                    />
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}