import {Fragment} from 'react'
import { Grid,Loader,Paper, Table, Typography } from "../../components";
import moment from "moment";
import { useAxios } from '../../hooks'
import { GlobalStyles, useTheme } from "@mui/material";
import { CustomerLayout } from "../../components";

export function CustomerDash () {
    const theme = useTheme()
    const [customers] = useAxios(`/customer/external-dash`)

    return (
        <Fragment>
            <GlobalStyles
            styles={{
                body: { backgroundColor: theme.palette.primary.main }
            }}
            />
        <CustomerLayout>
            <Grid style={{
                backgroundColor: theme.palette.primary.main
            }} 
            container 
            display="flex" 
            justifyContent="center" 
            spacing={2} 
            mt={5}>
                {
                    !customers.loading ? (
                        <Fragment>
                            <Grid item xs={12}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={3}>
                                        <Paper elevation={1} style={{padding: 15}}>
                                            <Typography textAlign="center" variant="h5">Saldo</Typography>
                                            <Typography textAlign="center" fontWeight="bold" mt={2} fontSize={18}>{Number(customers.data?.balance || 0).toLocaleString('pt-BR')}</Typography>
                                        </Paper>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Paper elevation={1} style={{padding: 15}}>
                                                <Typography textAlign="center" variant="h5">Pedidos</Typography>
                                                <Typography textAlign="center" fontWeight="bold" mt={2} fontSize={18}>{Number(customers.data?.customer_point.filter((v: {order: {id: number}}) => !!v.order).length || 0).toLocaleString('pt-BR')}</Typography>
                                                <Typography></Typography>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={10}>
                                            <Paper elevation={1}>
                                                <Table 
                                                    columns={[
                                                        { field: 'id', headerName: 'ID', width: 100 },
                                                        { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                                            return moment(value).format('DD/MM/YYYY HH:mm:ss')
                                                        } },
                                                        { field: 'amount', headerName: 'Pontos', flex: 1, valueFormatter: ({value}) => Number(value).toLocaleString('pt-BR'), renderCell: ({value, row}) => {
                                                            let color
                                                            let prefix

                                                            if (row.amount > 0) {
                                                                color = theme.palette.success.main
                                                                prefix = '+'
                                                            }

                                                            if (row < 0) {
                                                                color = theme.palette.error.main
                                                                prefix = '-'
                                                            }
                                                            
                                                            return (
                                                                <Typography color={color} fontWeight='bold'>
                                                                    {prefix}{value}
                                                                </Typography>
                                                            )
                                                        }},
                                                        { field: 'order', headerName: 'Pedido', flex: 1, valueGetter: ({row}) => {
                                                            return row?.order ? `${row.order.airline} (${row.order.reservation_code})` : '-'
                                                        }},
                                                        { field: 'description', headerName: 'Descrição', flex: 1},
                                                    ]}
                                                    rows={customers?.data?.customer_point || []}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Fragment>
                    ) : (
                        <Loader color='inherit' />
                    )
                }
            </Grid>
        </CustomerLayout>
        </Fragment>
    )
}