import { Button, Grid, Layout, Paper, Table, Loader, ButtonGroup, Menu } from "../../components";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { useAPI, useAxios } from '../../hooks'
import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth";
import { Chip, Link } from "@mui/material";
import { formatMoney, getPaymentStatusColor, getPaymentStatusLabel, getSupportTicketStatusColor, getSupportTicketStatusLabel } from "../../utils";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from "react-router-dom";

export function SupportTickets () {
    const navigate = useNavigate()
    const [tickets] = useAxios(`/support-ticket`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={3} spacing={2}>
                {tickets.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                <Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 100 },
                            { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'user_name', headerName: 'Criado por', flex: 1, valueGetter: ({row}) => row.user.name, renderCell: ({row, value}) => {
                                return (
                                    <Link href={`/admin/usuarios/${row.user_id}`}>
                                        {value}
                                    </Link>
                                )
                            }},
                            { field: 'bid_id', headerName: 'ID da Oferta', width: 200, renderCell: ({row}) => {
                                return row.bid_id ? (
                                    <Link
                                        href={`/admin/ofertas/${row.bid_id}`}
                                    >
                                        #{row.bid_id}
                                    </Link>
                                ) : '-'
                            } },
                            { field: 'message', headerName: 'Mensagem', flex: 2, valueGetter: ({row}) => row.support_ticket_messages[0].message },
                            {
                                field: 'status', headerName: 'Status', flex: 1, 
                                valueGetter: ({value}) => {
                                    return getSupportTicketStatusLabel(value)
                                },
                                renderCell: ({ formattedValue, row }) => {
                                    return (
                                        <Chip color={getSupportTicketStatusColor(row.status)} label={formattedValue} />
                                    )
                                }
                            },
                            {
                                field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                    return (
                                        <ButtonGroup>
                                            <Menu
                                                options={[
                                                    {
                                                        label: 'Ver Detalhes', 
                                                        onClick: async () => {
                                                            navigate(`/admin/chamados/${row.id}`)
                                                        }
                                                    }
                                                ]}
                                            >
                                                <MoreHorizIcon />
                                            </Menu>
                                        </ButtonGroup>
                                    )
                                }
                            }
                        ]}
                        rows={(tickets?.data || [])}
                    />
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}