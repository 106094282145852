import { Autocomplete, Button, Grid, Input, Layout, Loader, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { CARD_BRAND_OPTIONS } from "../../../constants";
import { CreateCardValidationSchema } from "@next-pontos/validations";
import { useAPI, useAxios } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import { Tooltip } from "@mui/material";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { formatCreditCard, formatCVV, formatExpiry } from "../../../utils";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { enqueueSnackbar } from "notistack";

export function CardId () { 
    const [focusedField, setFocusedField] = useState<'number' | 'cvc' | 'expiry' | 'name'>()
    const {id} = useParams()

    const [card] = useAxios(`/card/${id}`)
    const [cardPayload, refetchCardPayload] = useAxios(`/card/${id}/payload`, {
        manual: true
    })

    const [updatedCard, updateCard] = useAxios({
        url: `/card/${id}`,
        method: 'PUT'
    }, {manual: true})

    const [banks] = useAxios('https://brasilapi.com.br/api/banks/v1')

    const formik = useFormik({
        initialValues: {
            number: '',
            expiry: '',
            cvv: '',
            brand: '',
            bank: '',
            holder_name: '',
            alias: ''
        },
        onSubmit: async values => {
            const {status} = await updateCard({ data: values })

            if (status >= 200) {
                enqueueSnackbar('Cartão atualizado com sucesso!', {
                    variant: 'success'
                })
                
                window.location.replace(`/admin/cartoes`)
            }
        },
        initialStatus: 'draft',
        validateOnMount: true,
        validationSchema: CreateCardValidationSchema
    })

    const bankOptions = useMemo(() => {
        return (banks?.data || [])
            .filter((item: {code: number}) => !!item.code)
            .map((item: {name: string, code: number}) => ({
                value: item.name,
                label: `${item.code} - ${item.name}`
            }))
            .sort((a:  { value: string }, b: { value: string }) => a?.value?.localeCompare(b.value))
    }, [banks.data])
    
    useEffect(() => {
        if (card.data) {
            formik.resetForm({
                values: {
                    number: '',
                    cvv: '',
                    expiry: '',
                    holder_name: '',
                    alias: card.data.alias,
                    bank: card.data.bank,
                    brand: card.data.brand,
                },
                status: 'initiated',
            })
        }
    }, [card.data])

    useEffect(() => {
        if (cardPayload.data) {
            formik.setValues({
                ...formik.values,
                number: formatCreditCard(cardPayload.data.number || ''),
                cvv: cardPayload.data.cvv || '',
                expiry: cardPayload.data.expiry || '',
                holder_name: cardPayload.data.holder_name || ''
            })
        }
    }, [cardPayload.data])

    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} alignItems="center" padding={3} >
                {(card.loading || updatedCard.loading || banks.loading) ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Grid item xs={12} sm={4}>
                    <Paper elevation={1}>
                        <form onSubmit={formik.handleSubmit}>
                        <Grid container padding={2} spacing={2} justifyContent="center">
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Editar Cartão</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Cards
                                    number={formik.values.number}
                                    expiry={formik.values.expiry}
                                    cvc={formik.values.cvv}
                                    name={formik.values.holder_name}
                                    acceptedCards={['visa', 'mastercard', 'amex', 'elo']}
                                    callback={({issuer}) => {
                                        const brand = formik.values.brand
                                        const number = formik.values.number.replace(/\D/ig, '')
                                        
                                        if (issuer === 'american-express' && brand !== 'AMEX') {
                                            formik.setFieldValue('brand', 'AMEX')
                                        } else if (issuer === 'visa' && brand !== 'VISA') {
                                            formik.setFieldValue('brand', 'VISA')
                                        } else if (issuer === 'elo' && brand !== 'ELO') {
                                            formik.setFieldValue('brand', 'ELO')
                                        } else if (issuer === 'mastercard' && brand !== 'MASTERCARD') {
                                            formik.setFieldValue('brand', 'MASTERCARD')
                                        } else if (issuer === 'unknown' && !!brand && !!number) {
                                            formik.setFieldValue('brand', '')
                                        }
                                    }}
                                    focused={focusedField}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                                <Autocomplete 
                                    label="Banco"
                                    value={bankOptions.find((item: { value: string }) => item.value === formik.values.bank)}
                                    options={bankOptions}
                                    onChange={({value}) => {
                                        formik.setFieldValue('bank', value)
                                    }}
                                    name="bank"
                                    onBlur={formik.handleBlur}
                                    helperText={(formik.touched.bank && formik.errors.bank)}
                                    error={!!formik.touched.bank && !!formik.errors.bank }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip
                                    title="Por exemplo: Smiles Visa Inifite do João"
                                >
                                    <Input 
                                        label="Apelido"
                                        name="alias"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.alias}
                                        helperText={formik.touched.alias && formik.errors.alias}
                                        error={!!formik.touched.alias && !!formik.errors.alias}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}> 
                                <Input 
                                    label="Bandeira"
                                    name="brand"
                                    select
                                    disabled
                                    options={CARD_BRAND_OPTIONS}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.brand}
                                    helperText={formik.touched.brand && formik.errors.brand}
                                    error={!!formik.touched.brand && !!formik.errors.brand}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'flex'
                                    }}
                                >
                                {!cardPayload.data && (<div style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    zIndex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <Button variant="text" size="large" onClick={() => refetchCardPayload()}>
                                        <LockOpenIcon fontSize="large"/>
                                    </Button>    
                                </div> )}
                                <Grid container spacing={2} style={{
                                    filter: !cardPayload.data ? 'blur(5px)' : undefined
                                }}>
                                    <Grid item xs={12}>
                                        <Input 
                                            label="Número"
                                            name="number"
                                            onChange={(event) => {
                                                formik.setFieldValue('number', formatCreditCard(event.target.value))
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.number}
                                            onFocus={() => {
                                                setFocusedField('number')
                                            }}
                                            helperText={formik.touched.number && formik.errors.number}
                                            error={!!formik.touched.number && !!formik.errors.number}
                                        />
                                    </Grid>
                                    <Grid item xs={6}> 
                                    <Input 
                                        label="CVV"
                                        name="cvv"
                                        onChange={(event) => {
                                            formik.setFieldValue('cvv', formatCVV(event.target.value))
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.cvv}
                                        onFocus={() => {
                                            setFocusedField('cvc')
                                        }}
                                        helperText={formik.touched.cvv && formik.errors.cvv}
                                        error={!!formik.touched.cvv && !!formik.errors.cvv}
                                    />
                                </Grid>
                                <Grid item xs={6}> 
                                    <Input 
                                        label="Validade"
                                        name="expiry"
                                        onChange={(event) => {
                                            formik.setFieldValue('expiry', formatExpiry(event.target.value))
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.expiry}
                                        onFocus={() => {
                                            setFocusedField('expiry')
                                        }}
                                        helperText={formik.touched.expiry && formik.errors.expiry}
                                        error={!!formik.touched.expiry && !!formik.errors.expiry}
                                    />
                                </Grid>
                                <Grid item xs={12}> 
                                    <Input 
                                        label="Nome do Titular"
                                        name="holder_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.holder_name}
                                        helperText={formik.touched.holder_name && formik.errors.holder_name}
                                        error={!!formik.touched.holder_name && !!formik.errors.holder_name}
                                    />
                                </Grid>
                            <Grid item marginTop={2} textAlign="center" xs={12}> 
                                <Button disabled={!formik.isValid} type="submit">Salvar Cartão</Button>
                            </Grid>
                                </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        </form>
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}