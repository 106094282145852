import { Button, Grid, Layout, Paper, Table, Loader, Modal, CreateCustomerModal, Typography } from "../../components";
import moment from "moment";
import { useAxios, useToggle } from '../../hooks'
import { Fragment, useCallback, useState } from "react";
import { Link, useTheme } from "@mui/material";
import { GridActionsCellItem, GridAddIcon, GridEventListener, GridRowEditStopReasons, GridRowId, GridToolbarContainer } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { enqueueSnackbar } from "notistack";
import { isAxiosError } from "axios";
import { formatCNPJ, formatCPF } from "../../utils";
import { parsePhoneNumber } from "libphonenumber-js";
import { NewCustomers } from "./NewCustomers";

export function Customers () {
    const theme = useTheme()
    const [isDeleteModalVisible, isDeleteModalVisibleControls] = useToggle(false)
    const [customers, customersControls] = useAxios(`/customer`)
    const [, postCustomer] = useAxios(``, { manual: true })
    const [deleteRowId, setDeleteRowId] = useState<number | string | undefined>()
    const [isUpdateModalVisible, isUpdateModalVisibleControls] = useToggle(false)
    const [isCreateModalVisible, isCreateModalVisibleControls] = useToggle(false)
    const [customerIdToUpdate, setCustomerIdToUpdate] = useState<number | null>(null)

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (id: GridRowId) => () => {
        isUpdateModalVisibleControls.setTrue()
        setCustomerIdToUpdate(id as number)
    };
  
    const handleDeleteClick = (id: GridRowId) => () => {
        isDeleteModalVisibleControls.setTrue()

        setDeleteRowId(id)
    };
  
  
    const processRowDelete = useCallback(() => {
        postCustomer({
            url: `/customer/${deleteRowId}`,
            method: 'DELETE',
        }).then((result) => { 
            if (!isAxiosError(result)) {
                isDeleteModalVisibleControls.setFalse()
                enqueueSnackbar('Cliente excluído com sucesso!', {variant: 'success'}) 
                setDeleteRowId(undefined)
                customersControls()
            }
        })
    }, [customersControls, deleteRowId, isDeleteModalVisibleControls, postCustomer])

    return (
        <Fragment>
            <Modal
                open={isDeleteModalVisible}
                title="Atenção!"
                onConfirm={processRowDelete}
                confirmLabel="Confirmar"
                bodyCustomCss={{
                    width: 500
                }}
                onClose={isDeleteModalVisibleControls.setFalse}
            >
                <div>
                    <span>Você tem certeza que deseja deletar esse cliente?</span>
                    <br />
                    <span>Esta ação é irreversível e não pode ser desfeita.</span>
                    <br />
                    <br />
                    <span style={{fontStyle: 'italic', fontSize: 13}}>
                        Obs.: Caso hajam emissões vinculadas ao cliente, ele não será excluído.
                    </span>
                </div>
            </Modal>
            {customerIdToUpdate && (<CreateCustomerModal 
                onClose={() => {
                    isUpdateModalVisibleControls.setFalse()
                    setCustomerIdToUpdate(null)
                }}
                open={isUpdateModalVisible}
                onSubmit={() => {
                    enqueueSnackbar('Cliente atualizado com sucesso!', {variant: 'success'}) 
                    customersControls()
                }}
                customerId={customerIdToUpdate}
            />)}
            <CreateCustomerModal 
                onClose={isCreateModalVisibleControls.setFalse}
                open={isCreateModalVisible}
                onSubmit={() => {
                    enqueueSnackbar('Cliente adicionado com sucesso!', {variant: 'success'}) 
                    customersControls()
                }}
            />
            <Layout>
                <Grid container justifyContent="center" padding={3} spacing={2}>
                    {customers.loading ? (
                        <div style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Loader />
                        </div>
                    ) : (
                    <Fragment>
                    <Grid item xs={12} sm={10} height={'40vh'}>
                        <Paper elevation={1} sx={{
                            height: '100%',
                            padding: 2,
                        }}>
                            <NewCustomers />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Paper elevation={1}>
                        <Table 
                            columns={[
                                { field: 'id', headerName: 'ID', width: 20, type: 'string'},
                                { field: 'created_at', headerName: 'Criado em', width: 200, type: 'dateTime', valueFormatter: ({value}) => {
                                    return moment(value).format('DD/MM/YYYY HH:mm:ss')
                                } },
                                { field: 'creator', headerName: 'Criado por', flex: 1, type: 'string', renderCell: ({row, value}) => {
                                    return (
                                        <Link href={`/admin/usuarios/${row.creator?.id}`}>
                                            {value.name}
                                        </Link>
                                    )
                                }},
                                { field: 'name', headerName: 'Nome', flex: 1, type: 'string' },
                                { field: 'is_business', headerName: 'Tipo', flex: 1, type: 'singleSelect', valueOptions: ['Pessoa Física', 'Pessoa Jurídica'], valueGetter: ({value}) => value ? 'Pessoa Jurídica' : 'Pessoa Física' },
                                { field: 'document_number', headerName: 'CPF/CNPJ', flex: 1, type: 'string', valueFormatter(params) {
                                    const row = params.api.getRow(params.id as string)
                                    
                                    return row.is_business ? formatCNPJ(params.value) : formatCPF(params.value)
                                } },
                                { field: 'phone_number', headerName: 'Telefone', flex: 1, type: 'string', valueFormatter: ({value}) => {
                                    return value ? parsePhoneNumber(`+${value}`).formatInternational() : ''
                                } },
                                { field: 'email', headerName: 'Email', flex: 1, type: 'string', },
                                { field: 'customer_point_balance', headerName: 'Pontos', flex: 1, type: 'number', valueFormatter: ({value}) => Number(value).toLocaleString('pt-BR'), renderCell: ({formattedValue}) => {
                                    return (
                                        <Typography fontSize={14} fontWeight="bold" color={theme.palette.primary.main}>
                                            {formattedValue}
                                        </Typography>
                                    )
                                }},
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    width: 100,
                                    cellClassName: 'actions',
                                    getActions: ({ id }) => {
                                    return [
                                        <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={handleEditClick(id)}
                                        color="inherit"
                                        />,
                                        <GridActionsCellItem
                                        icon={<DeleteIcon />}
                                        label="Delete"
                                        onClick={handleDeleteClick(id)}
                                        color="inherit"
                                        />,
                                    ];
                                    },
                                }
                            ]}
                            rows={(customers.data || [])}
                            editMode="row"
                            onRowEditStop={handleRowEditStop}
                            slots={{
                                toolbar: () => (
                                    <GridToolbarContainer>
                                        <Button color="primary" startIcon={<GridAddIcon />} onClick={isCreateModalVisibleControls.setTrue}>
                                            Adicionar
                                        </Button>
                                    </GridToolbarContainer>
                                )
                            }}
                        />
                        </Paper>
                    </Grid>
                    </Fragment>)}
                </Grid>
            </Layout>
        </Fragment>
    )
}