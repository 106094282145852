import { useFormik } from "formik";
import { Grid, Button, Input, Typography } from "../../components";
import {SignInValidationSchema } from '@next-pontos/validations'
import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

export function Login () { 
    const { signIn } = useContext(AuthContext)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        onSubmit: async (values) => {
            await signIn(values)
            navigate('/home')
        },
        validationSchema: SignInValidationSchema,
        validateOnMount: true
    })
    
    return (
        <Fragment>
            <Grid container justifyContent="center" minHeight={"100vh"} alignItems="center" style={{
                backgroundColor: 'white',
                backgroundImage: 'url(/background.png)',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                flexDirection: 'column'
            }}>
                <Grid item xs={12} sm={4}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                            <img src="/logo.png" alt="Logo" style={{width: '50%',}}/>
                            <Grid item xs={12}>
                            <Typography textAlign={"center"}>
                                Next Gate Milhas - Consolidadora de Milhas com IATA
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            <Typography textAlign={"center"} fontStyle={"italic"} fontSize={14} mt={-2}>
                                Número IATA: 96177270
                            </Typography>
                            </Grid>
                            <Grid item xs={12} mt={1}> 
                                <Input 
                                    label="Email ou CPF"
                                    name="login"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.login}
                                    helperText={formik.touched.login && formik.errors.login}
                                    error={!!formik.touched.login && !!formik.errors.login}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    label="Senha"
                                    type="password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    error={!!formik.touched.password && !!formik.errors.password}
                                />
                            </Grid>
                            <Grid item marginTop={2} xs={12}>
                                <Button disabled={!formik.isValid} type="submit">Entrar</Button>
                            </Grid>
                            <Grid item marginTop={2} xs={12}>
                                <Typography>
                                    Esqueceu sua senha? <Link href='/esqueci-minha-senha'>Clique aqui!</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Ainda não tem cadastro? <Link href='/cadastro'>Clique aqui!</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={5}>
                                <Typography>
                                    Quer comprar milhas? <Link href={`https://api.whatsapp.com/send?phone=5511918569055&text=Quero%20comprar%20milhas%21`} target='_blank'>Clique aqui!</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Fragment>
    )
}