import { Menu, ButtonGroup, Grid, Layout, Paper, Table, Loader } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { useContext } from "react";
import AuthContext from "../../context/auth";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useNavigate} from 'react-router-dom'
import { useAxios } from "../../hooks";

export function MyAccounts () {
    const navigate = useNavigate()
    const { me } = useContext(AuthContext)

    const [accounts] = useAxios(`/account?user_id=${me?.id.toString()}`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} >
                {accounts.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) :(<Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 100 },
                            { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'company', headerName: 'Programa', flex: 1, valueGetter: ({value}) => {
                                return COMPANY_OPTIONS.find(item => item.value === value)?.label
                            } },
                            { field: 'holder_name', headerName: 'Nome do Titular', flex: 1 },
                            { field: 'holder_email', headerName: 'E-mail do Titular', flex: 1 },
                            { field: 'holder_phone_number', headerName: 'Telefone do Titular', flex: 1 },
                            { field: 'login', headerName: 'Login', flex: 1 },
                            {
                                field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                    return (
                                        <ButtonGroup>
                                            <Menu
                                                options={[
                                                    { 
                                                        label: 'Ver Detalhes', 
                                                        onClick: async () => {
                                                            navigate(`/minhas-contas/${row.id}`)
                                                        }
                                                    },
                                                ]}
                                            >
                                                <MoreHorizIcon />
                                            </Menu>
                                        </ButtonGroup>
                                    )
                                }
                            }
                        ]}
                        rows={accounts?.data || []}
                    />
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}