import { Grid, Menu, Layout, Paper, Table, ButtonGroup, Loader, Typography, Alert, MultipleAutocomplete, MultipleAutocompleteOption, DateRangePicker } from "../../../components";
import useSWR from 'swr'
import API from "../../../API";
import moment from "moment";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Chip, Link, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, formatMoney, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import { useAxios } from "../../../hooks";
import { Fragment, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { groupBy, sum, sumBy, values } from "lodash";
import { Line } from "recharts";
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid";
import { CARD_BRAND_OPTIONS } from "../../../constants";

const today = moment().toDate()
const oneMonth = moment().add(5, 'days').toDate()

const diff = moment(oneMonth).diff(today, 'days')

const month: Array<Date> = []

for (let day = 0; day < diff; day++) {
    month.push(moment(today).add(day, 'day').toDate())
}

const statusOptions = [
    { value: 'AWAITING_ORDER_CONFIRMATION', label: 'Aguardando Confirmação' },
    { value: 'CANCELLED', label: 'Cancelado' },
    { value: 'PENDING', label: 'Pendente' },
    { value: 'PAID', label: 'Pago' },
    { value: 'LATE', label: 'Atrasado' },
]

function DateRangeSelector(props: GridFilterInputValueProps) {
    const { item, applyValue } = props;
  
    return (
        <DateRangePicker 
            endDate={item.value?.endDate}
            startDate={item.value?.startDate}
            onChange={(value) => {
                applyValue({
                    ...item,
                    value
                })
            }}
        />
    );
  }
  
const dateRangeSelectorOperator: GridFilterOperator[] = [
{
    label: 'é entre',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value?.startDate || !filterItem.value?.endDate) {
            return null
        }

        return ({ value }) => {
            return moment(value).isAfter(new Date(filterItem.value.startDate)) && moment(value).isBefore(new Date(filterItem.value.endDate))
        };
    },
    InputComponent: DateRangeSelector,
}]; 

export function Fees () {
    const [orders] = useAxios(`/order`)
    
    return (
        <Grid container justifyContent="center" /*minHeight={"calc(100vh - 64px)"}*/ padding={3} gap={3} >
            {orders.loading ? (
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Loader />
                </div>
            ) :(
            <Fragment> 
                <Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'Emissão', width: 120, renderCell: ({value}) => {
                                return (
                                    <Link href={`/admin/emissoes/${value}`}>
                                        # {value}
                                    </Link>
                                )
                            }},
                            { field: 'created_at', headerName: 'Data da Emissão', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            }, filterOperators: dateRangeSelectorOperator },
                            {
                                field: 'card.brand', headerName: 'Bandeira', flex: 1, valueGetter: ({row}) => {
                                    return CARD_BRAND_OPTIONS.find(item => item.value === row.card.brand)?.label
                                }
                            },
                            {
                                field: 'card.bank', headerName: 'Banco', flex: 1, valueGetter: ({row}) => {
                                    return row.card.bank
                                }
                            },
                            {
                                field: 'card.alias', headerName: 'Cartão de Crédito', flex: 1, valueGetter: ({row}) => {
                                    return row.card.alias
                                }
                            },
                            {
                                field: 'card.last_four_digits', headerName: '4 Últimos Dígitos', flex: 1, valueGetter: ({row}) => {
                                    return row.card.last_four_digits
                                }
                            },
                            { field: 'fees', headerName: 'Valor', width: 120, valueFormatter: ({value }) => {
                                return formatMoney(value)
                            }},
                        ]}
                        rows={(orders?.data || [])}
                    />
                    </Paper>
                </Grid>
            </Fragment>
            )}
        </Grid>
    )
}