import { Button, Grid, Layout, Paper, Table, Loader } from "../../components";
import moment from "moment";
import { useAxios } from '../../hooks'
import { Fragment } from "react";
import { Link } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";

export function Leads () {
    const [leads] = useAxios(`/lead`)
    
    return (
        <Layout>
            <Grid container justifyContent="center" padding={3} spacing={2}>
                {leads.loading ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>

                    <Grid item xs={12} sm={10} textAlign="right">
                     <CSVLink
                        data={leads?.data.map((user: { email: string}) => ([
                            user.email
                        ]))}
                        filename="sendy-export.csv"
                        >
                            <Button>
                                <ImportExportIcon fontSize={"small"} style={{marginRight: 5}}/> Sendy
                            </Button>
                    </CSVLink>
                    </Grid>
                <Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', headerName: 'ID', width: 100 },
                            { field: 'created_at', headerName: 'Criado em', width: 200, type: 'dateTime', valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'email', headerName: 'Email', flex: 1, type: 'string', renderCell: ({row, value}) => {
                                return row.user?.id ? (
                                    <Link href={`/admin/usuarios/${row.user?.id}`}>
                                        {value}
                                    </Link>
                                ) : value
                            }},
                            { field: 'bids', headerName: 'Ofertas', flex: 1, type: 'number', valueGetter: ({row}) => {
                                return row.lead_bid.length

                            }},
                            { field: 'converted', headerName: 'Convertido', flex: 1, type: 'boolean', valueGetter: ({row}) => {
                                return row?.user?.id || false

                            }},
                            {
                                field: 'actions', 
                                type: 'actions',
                                width: 100,
                                cellClassName: 'actions',
                                getActions: ({ id }) => {
                                  return [
                                    <Link href={`/admin/leads/${id}`}>
                                        <GridActionsCellItem
                                            icon={<RemoveRedEyeIcon />}
                                            label="Ver Detalhes"
                                            color="inherit"
                                        />
                                    </Link>
                                  ];
                                },
                            }
                        ]}
                        rows={(leads?.data || [])}
                    />
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}