import { Button, CurrencyInput, Grid, Input, Layout, Loader, NumericInput, Radio, Table, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { COMPANY_OPTIONS } from "../../../constants";
import { CreateBidValidationSchema } from "@next-pontos/validations";
import { useAPI, useAxios } from "../../../hooks";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import {useMemo, useEffect, useCallback} from 'react'
import useSWR from 'swr'
import API from "../../../API";
import moment from "moment";
import { formatCPF } from "../../../utils";

export function SelectBidAccount () { 
    const params = useParams()
    
    const [bid, refetchBid] = useAxios(`/bid/${params.id}`)
    const [accounts, getAccounts] = useAxios(`/account?company=${bid?.data?.company}`, {
        manual: true
    })

    useEffect(() => {
        if (bid?.data?.company) {
            getAccounts()
        }
    }, [bid?.data?.company, getAccounts])

    const [selectAccount, selectAccountAPI] = useAxios({
        url: `/bid/${params.id}/select-account/`,
        method: 'PUT'
    }, {
        manual: true
    })

    const handleAccountSelect = useCallback(async (value: string | number) => {
        await selectAccountAPI({
            url: `/bid/${params.id}/select-account/${value}`
        })
        
        getAccounts()
        refetchBid()
    }, [params.id, getAccounts, selectAccountAPI, refetchBid])

    return (
        <Layout>
            <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} >
                {(bid.loading || accounts.loading || selectAccount.loading) ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Grid item xs={12} sm={10}>
                    <Grid container paddingBottom={2} justifyContent="flex-start">
                        <Grid item xs={4} justifyContent="flex-start">
                            <Button href="/contas/nova">
                                Nova Conta
                            </Button>
                        </Grid>
                    </Grid>
                    <Paper elevation={1}>
                    <Table
                        columns={[
                            {
                                field: 'actions', width: 55, headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                    return (
                                        <Radio 
                                            checked={row.id === bid.data.account_id}
                                            value={row.id}
                                            onChange={(event) => {
                                                handleAccountSelect(event.target.value)
                                            }}
                                        />
                                    )
                                }
                            },
                            { field: 'id', headerName: 'ID', width: 100 },
                            { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'company', headerName: 'Companhia', width: 120 },
                            { field: 'holder_name', headerName: 'Nome do Titular', flex: 1 },
                            { field: 'holder_cpf', headerName: 'CPF do Titular', width: 150, valueFormatter: ({value}) => {
                                return formatCPF(value)
                            } },
                            { field: 'holder_email', headerName: 'E-mail do Titular', flex: 1 },
                            { field: 'holder_phone_number', headerName: 'Telefone do Titular', flex: 1 },
                            { field: 'login', headerName: 'Login', flex: 1 },
                        ]}
                        rows={accounts?.data?.map((item: any) => ({
                            id: item.id,
                            created_at: item.created_at,
                            company: COMPANY_OPTIONS.find(cia => cia.value === item.company)?.label,
                            holder_name: item.holder_name,
                            holder_cpf: item.holder_cpf,
                            holder_email: item.holder_email,
                            holder_phone_number: item.holder_phone_number,
                            login: item.login
                        })) || []}
                    />
                    </Paper>
                </Grid>)}
            </Grid>
        </Layout>
    )
}